import React from 'react'
import {isDateTbd} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import {useEventDateInformation} from '../../../../../../../../commons/hooks/dates'
import sd from '../../../common/mobile-layout.scss'
import s from './date.scss'

export const Date = ({event}: {event: wix.events.Event}) => {
  const {shortStartDate} = useEventDateInformation(event.id)
  const dateTbd = isDateTbd(event)

  return (
    <div
      className={classNames(s.date, sd.eventNameFont, sd.eventNameColor)}
      data-hook={dateTbd ? 'ev-date-tbd' : 'ev-date'}
    >
      {shortStartDate}
    </div>
  )
}
