import {isImageVisible} from '../../../../selectors/list-settings'
import {renderIfVisible} from '../../../hoc/render-if-visible'
import {Image as ImagePresentation} from './image'

interface ImageOwnProps {
  event: wix.events.Event
  containerWidth?: number | string
  squareImage?: boolean
}

export interface ImageProps extends ImageOwnProps {}

export const Image = renderIfVisible<ImageOwnProps>(ImagePresentation, isImageVisible)
