import classNames from 'classnames'
import React, {CSSProperties} from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useWidgetState} from '../../../hooks/state-provider'
import {getComponentConfig, isListLayout} from '../../../selectors/settings'
import {useAdditionalItemsToggle} from '../../../hooks/use-additional-items-toggle'
import {ListItemContainerProps} from './interfaces'
import s from './list-item-container.scss'

export const ListItemContainer = ({
  event,
  mobile,
  dividerWidth,
  stripWidth,
  hideBorder,
  children,
}: ListItemContainerProps) => {
  const listLayout = useWidgetState(state => isListLayout(getComponentConfig(state)))
  const {opened, hovered, events: toggleEvents} = useAdditionalItemsToggle({event})

  const getContainerStyle = (): CSSProperties => {
    const padding = `${stripWidth + 10}px 0`

    return {
      padding,
      borderBottomWidth: hideBorder ? 0 : dividerWidth,
    }
  }

  return (
    <li
      style={getContainerStyle()}
      {...toggleEvents}
      data-hook={DH.listItem}
      className={classNames(s.container, mobile && !listLayout ? s.cardBorderColor : s.listDividerColor, {
        [s.listHoveredBorderColor]: listLayout && (hovered || opened) && !mobile,
      })}
    >
      {children(hovered)}
    </li>
  )
}
