import React from 'react'
import classNames from 'classnames'
import {useStyles} from '@wix/tpa-settings/react'
import {MainListTitle} from '../../main-list-title'
import {LoadMoreButton} from '../../load-more-button'
import {useWidgetState} from '../../../hooks/state-provider'
import {getEvents, hasMoreEvents} from '../../../selectors/events'
import {
  getCardBorderWidth,
  getComponentConfig,
  getListDividerWidth,
  getListStripWidth,
  isListLayout,
} from '../../../selectors/settings'
import {getListAlignmentClassName} from '../../../selectors/classes'
import {ListItemContainer} from '../../event-container/list-item-container'
import stylesParams from '../../../../stylesParams'
import {isMobile} from '../../../../../../commons/selectors/environment'
import s from './compact-mobile.scss'
import {CompactMobileItem} from './compact-mobile-item'

export const CompactMobile = () => {
  const styles = useStyles()
  const mobile = useWidgetState(isMobile)
  const events = useWidgetState(getEvents)
  const hasMore = useWidgetState(hasMoreEvents)
  const componentConfig = useWidgetState(getComponentConfig)
  const alignment = styles.get(stylesParams.listAlignment)
  const isDesktopListLayout = isListLayout(componentConfig)
  const dividerWidth = isDesktopListLayout ? getListDividerWidth(componentConfig) : getCardBorderWidth(componentConfig)
  const stripWidth = getListStripWidth(componentConfig, true, true)

  const className = classNames(s.container, isDesktopListLayout ? s.listContainer : s.cardContainer, {
    [s[getListAlignmentClassName(alignment)]]: true,
  })

  return (
    <div className={className} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) => (
          <ListItemContainer
            key={event.id}
            event={event}
            hideBorder={events.length - 1 === index && !hasMore}
            mobile={mobile}
            dividerWidth={dividerWidth}
            stripWidth={stripWidth}
          >
            {() => <CompactMobileItem event={event} />}
          </ListItemContainer>
        ))}
      </ul>
      <LoadMoreButton mobile />
    </div>
  )
}
