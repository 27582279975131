import {
  ALIGNMENT,
  LIST_ALIGNMENT,
  LIST_IMAGE_LAYOUT,
  VERTICAL_ALIGNMENT,
  WIDGET_LAYOUT,
} from '@wix/wix-events-commons-statics'

export const getAlignmentClassName = (alignment: number) => getClassName(ALIGNMENT, alignment)

export const getListAlignmentClassName = (alignment: number) => getClassName(LIST_ALIGNMENT, alignment)

export const getListImageLayoutClassName = (layout: number) => getClassName(LIST_IMAGE_LAYOUT, layout)

export const getVerticalAlignmentClassName = (alignment: number) => {
  const className = getClassName(VERTICAL_ALIGNMENT, alignment)
  return className === 'center' ? 'middle' : className
}

export const getLayoutClassName = (layout: number) => getClassName(WIDGET_LAYOUT, layout)

const getClassName = (property: object, value: number) =>
  Object.entries(property)
    .find(([key, val]) => val === value)?.[0]
    .toLowerCase()
