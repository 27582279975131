import React from 'react'
import classNames from 'classnames'
import {isDateVisible, isImageVisible, isLocationVisible, isSquareImage} from '../../../../selectors/list-settings'
import {getRibbonData} from '../../../../selectors/events'
import {Image} from '../../common/image'
import {useWidgetState} from '../../../../hooks/state-provider'
import {Ribbon} from '../../../ribbon'
import {RsvpButton} from '../../../rsvp-button'
import {Title} from './title'
import {Date} from './date'
import s from './compact-mobile-item.scss'
import {Location} from './location'

export const CompactMobileItem = ({event}: {event: ExtendedEvent}) => {
  const showImage = useWidgetState(isImageVisible)
  const showDate = useWidgetState(isDateVisible)
  const squareImage = useWidgetState(state => isSquareImage(state, true))
  const listShowLocation = useWidgetState(isLocationVisible)
  const showRibbon = useWidgetState(state => getRibbonData(state, event).visible)

  return (
    <div className={s.root}>
      {showImage && (
        <div className={classNames(s.image)}>
          <Image event={event} squareImage={squareImage} />
        </div>
      )}
      <div className={s.mainSection}>
        {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
        <Title event={event} />
        {showDate ? <Date event={event} /> : null}
        {listShowLocation ? <Location event={event} /> : null}
        <div className={s.button}>
          <RsvpButton event={event} minHeight={32} minWidth={100} className={s.buttonFont} />
        </div>
      </div>
    </div>
  )
}
