import classNames from 'classnames'
import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import sc from '../classes.scss'
import {useSettings} from '../../../hooks/settings'
import settingsParams from '../../../settingsParams'
import s from './main-list-title.scss'
import {MainListTitleProps} from '.'

export const MainListTitle = ({
  listLayout,
  listStripWidth,
  mobile,
  visible,
  cardsLayout,
  cardMargins,
  settings,
}: MainListTitleProps) => {
  const {get} = useSettings(settings)
  const title = get(settingsParams.listGeneralTitle)

  if (!visible) {
    return null
  }

  const fromTitleBaselineToCards = `calc(72px - .3em - ${cardMargins}px)`

  const padding = {
    mobileLayout: {
      padding: '20px 30px',
    },
    cardsLayout: {
      padding: `calc(72px - .3em) 30px ${fromTitleBaselineToCards}`,
    },
    listLayout: {
      padding: `55px 30px ${50 + listStripWidth}px`,
    },
    defaultLayout: {
      padding: `calc(72px - .3em) 30px calc(52px - .3em)`,
    },
  }

  return (
    <h1
      className={classNames(s.title, sc.textLineHeight, {[s.mobile]: mobile})}
      style={
        mobile
          ? padding.mobileLayout
          : cardsLayout
          ? padding.cardsLayout
          : listLayout
          ? padding.listLayout
          : padding.defaultLayout
      }
      data-hook={DH.listTitle}
    >
      {title}
    </h1>
  )
}
