import classNames from 'classnames'
import React from 'react'
import sc from '../../../../classes.scss'
import sd from '../../../common/mobile-layout.scss'
import {LinkToPage} from '../../../../link-to-page'
import s from './title.scss'

export const Title = ({event}: {event: wix.events.Event}) => {
  const classes = classNames(s.root, sc.textLineHeight, sd.eventNameFont, sd.eventNameColor)

  return (
    <span className={classes} data-hook="ev-list-item-title">
      <LinkToPage event={event}>{event.title}</LinkToPage>
    </span>
  )
}
